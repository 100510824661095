import React, { initialState, useRef, useState } from 'react'
import emailjs from '@emailjs/browser';

function ContactForm() {

    const form = useRef();

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);

    const [statusMessage, setStatusMessage] = useState("")
    const [statusClass, setStatusClass] = useState("")

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_qh3up0y', 'template_qrnrn5w', form.current, '6WnJZEQRHsSnCrefl')
          .then((result) => {
            ref1.current.value = '';
            ref2.current.value = '';
            ref3.current.value = '';
            ref4.current.value = '';
            ref5.current.value = '';

            setStatusMessage("Thank you, email sent successfully!");
            setStatusClass("alert-success-custom")
          }, (error) => {
            setStatusMessage(`${error.text} happened`);
            setStatusClass("alert-error-custom")
          });
      };

    return(
    <div className="callback-form contact-us">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="section-heading">
                    <h2>Send us a <em>message</em></h2>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="contact-form">
                    <form ref={form} onSubmit={sendEmail}>
                        <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <fieldset>
                            <input name="name" type="text" className="form-control" id="name" ref={ref1} placeholder="Full Name*" required="true"/>
                            </fieldset>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <fieldset>
                            <input name="email" type="text" className="form-control" id="email" ref={ref2} pattern="[^ @]*@[^ @]*" placeholder="E-Mail Address*" required="true"/>
                            </fieldset>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <fieldset>
                            <input name="phone" type="text" className="form-control" id="phone" ref={ref3} placeholder="Phone Number" required=""/>
                            </fieldset>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <fieldset>
                            <input name="business" type="text" className="form-control" id="business" ref={ref4} placeholder="Business Name (optional)" required=""/>
                            </fieldset>
                        </div>
                        <div className="col-lg-12">
                            <fieldset>
                            <textarea name="message" rows="6" className="form-control" id="message" ref={ref5} placeholder="Please provide a description of the service you require*" required="true"></textarea>
                            </fieldset>
                        </div>
                        <div className="col-lg-12">
                            <fieldset>
                            <input type="submit" className="border-button" value="Send Message" />
                            {/* <a type="submit" className="border-button">Send Message</a> */}
                            <p className={statusClass}>{statusMessage}</p>
                            </fieldset>
                        </div>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default ContactForm;