import ContactForm from '../Components/ContactForm';

function Contact() {
    return (
        <div className="contact-page">
            <ContactForm/>
            <div className="map-frame">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2593.3530002001744!2d-119.5866813!3d49.45894440000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5482898ff88a74c7%3A0xca2418e12f08d5ba!2s3313%20Wilson%20St%2C%20Penticton%2C%20BC%20V2A%208J3!5e0!3m2!1sen!2sca!4v1654466994380!5m2!1sen!2sca" width="100%" height="100%" style={{ border: 0 }} allowfullscreen="true" loading="lazy"></iframe>
            </div>
        </div>
    )
}

export default Contact;