import React from 'react'
import { NavLink } from "react-router-dom";
import fontawesome from '@fortawesome/fontawesome'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faPhone, } from '@fortawesome/fontawesome-free-solid'
import { faFacebook } from '@fortawesome/free-brands-svg-icons';

fontawesome.library.add(faClock, faPhone, faFacebook);

function SubHeader() {
  return (
    <div className="sub-header">
      <div className="container">
        <div className="row">
          <div className="col-md-5 col-xs-12">
            <NavLink className="navbar-brand" to="/"><img src={require('../images/logo.png')} /></NavLink>
          </div>
          <div className="col-md-7">
            <ul className="left-info">
              <li><FontAwesomeIcon icon="fa-clock" />Mon-Fri 09:00-17:00</li>
              <li><a href="#250-617-2515"><FontAwesomeIcon icon="fa-solid fa-phone" />250-617-2515</a></li>
              <NavLink className="border-button-header" exact activeclassName="active" to="/contact">
                Request a Service Call
              </NavLink>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubHeader;