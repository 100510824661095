import {Nav, Tab, Row, Col} from 'react-bootstrap'


function Industrial() {
    return(
        <div className="single-services">
        <div className="container">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row id="tabs">
          <Col sm={4}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="first">Instrumentation <i className="fa fa-angle-right"></i></Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={8}>
            <Tab.Content className="tabs-content">
              <Tab.Pane eventKey="first">
                <article>
                    <img src={require("../images/services/instrumentation.jpeg")} alt="" />
                    <h4>Instrumentation</h4>
                    <p>We can calibrate instruments according to your specific plant standards and preferences. We will work around your hours and project requirements, dispatching the right number of technicians with the matching skills for the job and the right fit for your company culture. 
                      We can perform calibrations on new instrument installations and support your existing operation and maintenance calibration needs. Our instruments calibration certifications are carry on using calibration standards that are traceable to NIST with all required documentation for traceability. We perform calibrations in all industrial instrumentation such as temperature (including recorders and mapping), level, pressure, pH, flow, and conductivity among others.</p>
                  </article>
              </Tab.Pane> 
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
        </div>
      </div>
    )
}

export default Industrial;