import {Nav, Tab, Row, Col} from 'react-bootstrap'


function Residential() {
    return(
        <div className="single-services">
        <div className="container">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row id="tabs">
          <Col sm={4}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="first">Hot Tub and Pool Wiring <i className="fa fa-angle-right"></i></Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">Light Installations <i className="fa fa-angle-right"></i></Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="third">Outlet & Switch Installation <i className="fa fa-angle-right"></i></Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="fourth">Wiring & Rewiring <i className="fa fa-angle-right"></i></Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={8}>
            <Tab.Content className="tabs-content">
              <Tab.Pane eventKey="first">
                <article>
                    <img src={require("../images/homepage/hottub.jpeg")} alt="" />
                    <h4>Hot Tub and Pool Wiring</h4>
                    <p>When you hire us to wire your hot tub, spa or pool, we’ll ensure that all Electrical Code requirements are met. 
                      We will carefully install and inspect underground wiring, voltage and amp power and ground-fault interrupter devices so that you never have to worry. A proper ground is key when installing a new pool, hot tub or spa – 
it ensures that electrocution is avoided in the event of a short circuit. All of our installations come with an Electrical Safety Authority Inspection Certificate and meet the pool, hot tub or spa manufacturer’s specifications. We’ll verify that all of the proper electrical equipment, components and wiring are used so that you can fully enjoy your new backyard oasis.</p>
                  </article>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
              <article>
                    <img src={require("../images/slide_04.jpg")} alt="" />
                    <h4>Light Installations</h4>
                    <p>Some of the most common lighting fixtures and systems we install are:
                      <br/>- Ceiling fans<br/>- Recessed lighting<br/>- Under-cabinet lighting<br/>- Indoor & outdoor LED lighting<br/>- Security & landscape lighting
<br/>- Lighting retrofit projects<br/>
Beyond chandeliers and recessed lighting, we also install exterior lighting. This includes security lighting that is motion detected and smart home security lighting that is attached to a camera. Our team keeps up to date on new technology and processes so that we can provide the best of the best service.</p>

                  </article>
              </Tab.Pane>
              <Tab.Pane eventKey="third">
              <article>
                    <img src={require("../images/slide_0134.jpg")} alt="" />
                    <h4>Outlet & Switch Installation</h4>
                    <p>Electrical panels are easily overlooked and forgotten, but it can be detrimental if things go wrong. Old electrical panels can malfunction or face a strong surge causing an electrical fire – which can be deadly. It’s imperative you upgrade your electrical panel if it is getting old and worn out.
                      The National Electronic Code (NEC) requires that arc-fault circuit interrupters (AFCIs) be installed on all circuits feeding nearly all the rooms in your house. The advantage of AFCIs is that they can differentiate between regular electrical arcs and faulty arcs. This distinction can help reduce the chance of an electrical fire.</p>
                  </article>
              </Tab.Pane>
              <Tab.Pane eventKey="fourth">
              <article>
                    <img src={require("../images/homepage/renovation.jpg")} alt="" />
                    <h4>Wiring & Rewiring</h4>
                    <p>If your home is older and has never been rewired, it can be more susceptible to fires and electrical shock accidents. In addition, the safety standards have changed and the wiring would not be up to code.

<br/>The benefits of rewiring your home include:<br/>

- Improved safety with the elimination of electrical issues<br/>
- Protection for your sensitive electronic devices<br/>
- Increased capacity for electronic devices<br/>
- Greater efficiency of your electrical system<br/>
- Lower monthly electric bills<br/>
- Added value to your home<br/>
Ensures your home is up to local and national electric codes
Some additional signs that your home is telling you it needs to be rewired are that you are experiencing chronic power outages, flickering lights, or scorched marks around your outlets.</p>
                  </article>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
        </div>
      </div>
    )
}

export default Residential;