function Footer() {
    return (
        <div>
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-md-6 footer-item">
              <img src={require('../images/footer-logo.png')}/>
                <p>Electrical services you can trust. Call us for a quote today.</p>
                {/* <ul className="social-icons">
                  <li><a rel="nofollow" href="https://fb.com/templatemo" target="_blank"><i className="fa fa-facebook"></i></a></li>
                  <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                  <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                  <li><a href="#"><i className="fa fa-behance"></i></a></li>
                </ul> */}
              </div>
              <div className="col-md-3 footer-item">
                <h4>Service Areas</h4>
                <ul className="menu-list">
                  <li>Penticton</li>
                  <li>Summerland</li>
                  <li>Peachland</li>
                  <li>Oliver</li>
                  <li>OK Falls</li>
                </ul>
              </div>
              <div className="col-md-3 footer-item">
                <h4>Pages</h4>
                <ul className="menu-list">
                <li><a href="/">Home</a></li>
                  <li><a href="/about-us">About Us</a></li>
                  <li><a href="/residential">Residential Services</a></li>
                  <li><a href="/industrial">Industrial Services</a></li>
                  <li><a href="/contact">Contact Us</a></li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
        
        <div className="sub-footer">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <p>Copyright &copy; 2022 Chiasson Power and Automation
                
                - Design: <a rel="nofollow noopener" href="https://skahadesigns.ca" target="_blank">Skaha Designs</a></p>
              </div>
            </div>
          </div>
        </div>
        </div>
    )
}

export default Footer;