import {Nav, Tab, Row, Col} from 'react-bootstrap'


function Services() {
    return(
        <div className="single-services">
        <div className="container">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row id="tabs">
          <Col sm={4}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="first">Market Analysis <i className="fa fa-angle-right"></i></Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">Financial Data <i className="fa fa-angle-right"></i></Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={8}>
            <Tab.Content className="tabs-content">
              <Tab.Pane eventKey="first">
                <article>
                    <img src={require("../images/homepage/hottub.jpeg")} alt="" />
                    <h4>Market Analysis</h4>
                    <p>Vivamus sed feugiat elit. Pellentesque pretium, massa at placerat vehicula, neque turpis pulvinar tortor, eget convallis lorem odio non tortor. Donec massa est, fermentum sit amet felis ac, maximus luctus elit. Vivamus aliquet, dolor id imperdiet imperdiet, dui diam aliquet dui, a euismod metus enim ac velit. Vivamus eu tristique odio, vel tristique quam.
                    <br/>Proin eu molestie risus. Etiam suscipit pretium odio, at consectetur nisi. Sed ut dolor in augue cursus ultrices. Vivamus mauris turpis, auctor vel facilisis in, tincidunt vel diam. Sed vitae scelerisque orci. Nunc non magna orci. Aliquam commodo mauris ante.</p>
                  </article>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
              <article>
                    <img src={require("../images/homepage/hottub.jpeg")} alt="" />
                    <h4>Market Analysis</h4>
                    <p>Vivamus sed feugiat elit. Pellentesque pretium, massa at placerat vehicula, neque turpis pulvinar tortor, eget convallis lorem odio non tortor. Donec massa est, fermentum sit amet felis ac, maximus luctus elit. Vivamus aliquet, dolor id imperdiet imperdiet, dui diam aliquet dui, a euismod metus enim ac velit. Vivamus eu tristique odio, vel tristique quam.
                    <br/>Proin eu molestie risus. Etiam suscipit pretium odio, at consectetur nisi. Sed ut dolor in augue cursus ultrices. Vivamus mauris turpis, auctor vel facilisis in, tincidunt vel diam. Sed vitae scelerisque orci. Nunc non magna orci. Aliquam commodo mauris ante.</p>
                  </article>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
        </div>
      </div>
    )
}

export default Services;