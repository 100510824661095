import React from "react";
import { NavLink } from "react-router-dom";

function Header() {
    return(
        <header>
        <nav className="navbar navbar-expand-lg">
        <div class="container">
            {/* <a className="navbar-brand" href="/"><img src={require('../images/logo.png')}/></a> */}
            <class className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            </class>
            <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ml-auto">
                <NavLink className="nav-item nav-link" exact activeclassName="active" to="/">
                    Home
                </NavLink>
                <NavLink className="nav-item nav-link" exact activeclassName="active" to="/about">
                    About Us
                </NavLink>
                <NavLink className="nav-item nav-link" exact activeclassName="active" to="/residential">
                    Residential
                </NavLink>
                <NavLink className="nav-item nav-link" exact activeclassName="active" to="/industrial">
                    Industrial
                </NavLink>
                <NavLink className="nav-item nav-link" exact activeclassName="active" to="/contact">
                    Contact Us
                </NavLink>
            </ul>
            </div>
        </div>
        </nav>
        </header>
    )
}

export default Header;