import React from 'react'
import ContactForm from '../Components/ContactForm';

function Home() {
    return (
        <div>
            <div className="Modern-Slider">
                <div className="item item-1">
                    <div className="img-fill">
                        <div className="overlay">
                            <div className="text-content">
                                <h6>CHIASSON POWER AND AUTOMATION</h6>
                                <h4>Electrical<br /> Services</h4>
                                <p>Over 10 years of electrical and instrumentation experience.</p>
                                <a href="contact" className="filled-button">contact us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="request-form">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h4>Looking for a Quote for Electrical Services?</h4>
                            <span>Send us an email or give us a call, we would love to hear from you!</span>
                            <br />
                            <a href="/contact" className="border-button">Request a Service Call</a>
                        </div>
                        <div className="col-md-4">
                        </div>
                    </div>
                </div>
            </div>

            <div className="services">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-heading">
                                <h2>Our Electrical <em>Services</em></h2>
                                <span>Helping you find effective solutions.</span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="service-item">
                                <div class="imgBox">
                                    <img src={require("../images/homepage/hottub.jpeg")} alt="" />
                                </div>
                                <div className="down-content">
                                    <h4>Hot Tub and Pool Wiring</h4>
                                    <p>Large Wiring
                                        <br />GFCI Protected Circuit Breaker<br />Grounding and Waterproof Connectors</p>
                                    <a href="/residential" className="filled-button">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="service-item">
                                <div class="imgBox">
                                    <img src={require("../images/slide_02.jpg")} alt="" />
                                </div>
                                <div className="down-content">
                                    <h4>House Renovations</h4>
                                    <p>Lighting Installations<br />Panel and Service Upgrades<br />Electrical Outlet And Switch Relocation</p>
                                    <a href="/residential" className="filled-button">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="service-item">
                                <div class="imgBox">
                                    <img src={require("../images/homepage/renovation.jpg")} alt="" />
                                </div>
                                <div className="down-content">
                                    <h4>New Construction</h4>
                                    <p>House, AC and Furnace Wiring<br />Panel installation<br />Solar Panal Installation</p>
                                    <a href="/residential" className="filled-button">Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ContactForm/>
        </div>
    )
}

export default Home;