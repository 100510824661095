function About() {
    return(
        <div className="more-info about-info">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="more-info-content">
                <div className="row">
                  <div className="col-md-6 align-self-center">
                    <div className="right-content">
                      <span>Electrician and Instrumentation Red Seal</span>
                      <h2>Get to know about <em>our company</em></h2>
                      <p>Blaine has been working in the industry for over 10 years. He has experience as a foreman working for
                        large gold and diamond companies. With focusing his career initially in electrical work, he is always increasing his skills
                        recently getting his instrumentation red seal. Blaine has also done residential work, providing services with commercial businesses
                        and custom homes. He excels at what he does, working with precision and efficiency.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="left-image">
                    <img src={require("../images/services/industrial.jpg")} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default About;