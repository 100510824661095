import React from "react";
import Footer from './Components/Footer'
import Header from './Components/Header'
import Home from './Pages/Home';
import About from './Pages/About';
import Residential from './Pages/Residential';
import Industrial from './Pages/Industrial';
import Services from './Pages/Services';
import Contact from './Pages/Contact';
import SubHeader from './Components/SubHeader'
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import './App.css';

function App() {
    return(
        <Router>
        <SubHeader/>
        <Header/>
        <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/residential" element={<Residential />} />
          <Route exact path="/industrial" element={<Industrial />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/contact" element={<Contact />} />
        </Routes>
        <Footer/>
      </Router>
    )
}

export default App;